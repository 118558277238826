import { messages } from './messages'
import { ConfigurationStorageTypes, Customizers, SaveFlows, Environments } from './constants'

const CustomizationFrameWrapperInternal = ({
  onConfigurationChanged,
  onSessionSavedAsync,
  getDistributorsAsync,
  targetDiv,
  marketingSku,
  sku,
  userId,
  configurationId,
  showConfigurator,
  showConfigSelector,
  showSave,
  showShare,
  saveFlow,
  initialConfiguration,
  configurationStorageType,
  customizer,
  environment,
  onHelpClicked,
  onProductInfoClicked,
  onCloseClicked,
  trace
}) => {
  let currentFrame
  let registeredCallbacks = {}

  const sendMessage = (event, data, correlationId) => {
    if (currentFrame?.contentWindow) {
      currentFrame.contentWindow.postMessage({
        source: 'met-configurator-frame',
        correlationId: correlationId,
        payload: {
          event: event,
          data: data
        }
      }, "*")
    }
  }
  const registerCallback = (event, correlationId, callback) => {
    registeredCallbacks[`${event}.${correlationId}`] = callback
  }
  const clearCallback = (event, correlationId) => {
    delete registeredCallbacks[`${event}.${correlationId}`]
  }
  const callCallback = (event, correlationId, data) => {
    if (registeredCallbacks[`${event}.${correlationId}`]) {
      registeredCallbacks[`${event}.${correlationId}`](data)
    }
    clearCallback(event, correlationId)
  }

  const messageListener = (event) => {
    if (event.data.source === 'met-configurator') {
      switch (event.data.payload.event) {
        case messages.configurationChanged:
          if (onConfigurationChanged) {
            onConfigurationChanged(event.data.payload.data)
          }
          break
        case messages.sessionSaved:
          handleSessionSaved(event.data.payload.data, event.data.correlationId);
          break;
        case messages.getDistributors:
          handleGetDistributorsAsync(event.data.payload.data, event.data.correlationId);
          break
        case messages.helpClicked:
          handleHelpCallback();
          break;
        case messages.productInfoClicked:
          handleProductInfoCallback(event.data.payload.data);
          break;
        case messages.closeClicked:
          handleCloseCallback();
          break;
        default:
          callCallback(event.data.source, event.data.correlationId)
          console.log('Unknown message', event)
      }
    }
  }

  const handleSessionSaved = (data, correlationId) =>{
    if(onSessionSavedAsync){
      onSessionSavedAsync(data).then(response =>{
        sendMessage(messages.sessionSavedSuccess, response, correlationId);
      }).catch(err=>{
        sendMessage(messages.sessionSavedError, err, correlationId);
      })
    }else{
      sendMessage(messages.sessionSavedSuccess, "", correlationId);
    }
  }

  const handleGetDistributorsAsync = (data, correlationId) =>{
    if(getDistributorsAsync){
      getDistributorsAsync(data).then(response =>{
        sendMessage(messages.getDistributorsSuccess, response, correlationId);
      }).catch(err=>{
        sendMessage(messages.getDistributorsError, err, correlationId);
      })
    }
  }

  const handleHelpCallback = () =>{
    if(onHelpClicked){
      onHelpClicked();
    }
  }

  const handleProductInfoCallback = (data) =>{
    if(onProductInfoClicked){
      onProductInfoClicked(data);
    }
  }

  const handleCloseCallback = () =>{
    if(onCloseClicked){
      onCloseClicked();
    }
  }

  const init = () => {
    if (!targetDiv) {
      throw new Error('targetDiv is required')
    }
    if (!customizer) {
      throw new Error('customizer is required')
    }

    if (currentFrame) {
      destroy()
    }

    window.addEventListener('message', messageListener)
    currentFrame = document.createElement('iframe')
    const currentFrameId = 'met-frame-' + new Date().valueOf()

    let params = []

    let url;
    switch (environment?.toLowerCase()){
      case Environments.preview:
        url = `https://custom-stage.milwaukeetool.com/external/ThreeKitFrame/${customizer}`;
        params.push(`e=${Environments.preview}`);
        break;
      case Environments.production:
      default:
        url = `https://custom.milwaukeetool.com/external/ThreeKitFrame/${customizer}`;
        params.push(`e=${Environments.production}`);
        break;
    }

    if(marketingSku){
      url += `/${marketingSku}`;
    }
    if(sku){
      params.push(`sku=${sku}`)
    }
    if(userId){
      params.push(`u=${userId}`);
    }
    if(configurationId){
      params.push(`cid=${configurationId}`);
    }
    if(showConfigurator === false){
      params.push(`c=0`);
    }
    if(showConfigSelector === false){
      params.push(`cs=0`);
    }
    if(showShare === false){
      params.push(`sh=0`);
    }
    if(showSave === false){
      params.push(`s=0`);
    }
    if(trace){
      params.push(`t=1`);
    }
    if(getDistributorsAsync){
      params.push(`d=1`);
    }
    if(onHelpClicked){
      params.push(`hc=1`);
    }
    if(onProductInfoClicked){
      params.push(`pc=1`);
    }
    if(onCloseClicked){
      params.push(`cc=1`);
    }
    if(initialConfiguration){
      params.push("ic=" + encodeURIComponent(JSON.stringify(initialConfiguration)));
    }
    if(saveFlow){
      params.push('sf=' + saveFlow?.toLowerCase());
    }
    if(configurationStorageType){
      params.push(`ss=${configurationStorageType}`);
    }

    if(params.length){
      url += "?" + params.join("&");
    }

    currentFrame.src = url;
    currentFrame.scrolling = "auto";
    currentFrame.id = currentFrameId
    currentFrame.style.display = "block";
    currentFrame.style.flexGrow = "1";
    currentFrame.style.border = "none";
    currentFrame.style.margin = "0";
    currentFrame.style.padding = "0";
    currentFrame.style.overflow = "visible";

    targetDiv.appendChild(currentFrame)
  }

  const destroy = () => {
    window.removeEventListener('message', messageListener)
    if (currentFrame) {
      currentFrame.parentNode.removeChild(currentFrame)
      currentFrame = undefined
    }
    registeredCallbacks = {};
  }

  init();

  return {
    destroy
  }
}

class CustomizationFrameWrapper {
  /**
   *
   * @param {function} onConfigurationChanged - called whenever the current configuration values change.
   * @param {function} onSessionSavedAsync - called at completion of the customization session (when adding to cart). This should return a promise.
   * @param {function} getDistributorsAsync - INTERNAL ONLY: Only used for MilwaukeeTool.com.
   * @param {node} targetDiv - The div to create the iframe within.
   * @param {string} [marketingSku] - The marketing group to load in the visualizer, currently only HEAD_PROTECTION and VEST supported.
   * @param {string} [sku] - The Milwaukee Tool sku to load in the visualizer.
   * @param {string} [userId] - FUTURE: The userId to tie saved configurations to.
   * @param {string} [configurationId] - The configuration id to load into the visualizer.
   * @param {boolean} [showConfigurator=true] - To allow editing of customization areas.
   * @param {boolean} [showConfigSelector=true] - To show the user configuration selector.
   * @param {boolean} [showSave=true] - To show done customizing/add to cart.
   * @param {boolean} [showShare=true] - To show the share button.
   * @param {SaveFlows|string} [saveFlow=SaveFlows.cart] - Controls the save flow, SaveFlows.cart will require a onSessionSavedAsync callback, SaveFlows.email will enable the user to email the customization to themselves.
   * @param {Object} [initialConfiguration] - Preload the customization with the provided values.
   * @param {ConfigurationStorageTypes|string} [configurationStorageType=ConfigurationStorageTypes.localStorage] - Controls how configurations are temporarily stored until saved.
   * @param {Customizers|string} [customizer=Customizers.personalProtectiveEquipment] - Specifies the customizer, currently only PPE supported.
   * @param {Environments|string} [environment=Environments.production] - Controls the environment being used.
   * @param {function} [onHelpClicked] - Called when help is clicked, if not provided will default to Milwaukee Tool customer service dialog.
   * @param {function} [onProductInfoClicked] - Called when product info is requested, if not provided, no product info button will be present.
   * @param {function} [onCloseClicked] - Called when close icon is clicked, if not provided, no close icon will be present.
   * @param {boolean} [trace=false] - Show debugging messages to console.
   * @returns {{destroy: function}} - Function to destroy the instance.
   */
  static init ({
    onConfigurationChanged,
    onSessionSavedAsync,
    getDistributorsAsync,
    targetDiv,
    marketingSku,
    sku,
    userId,
    configurationId,
    showConfigurator = true,
    showConfigSelector = true,
    showSave = true,
    showShare = true,
    saveFlow = SaveFlows.cart,
    initialConfiguration,
    configurationStorageType = ConfigurationStorageTypes.localStorage,
    customizer = Customizers.personalProtectiveEquipment,
    environment  = Environments.production,
    onHelpClicked,
    onProductInfoClicked,
    onCloseClicked,
    trace = false
  }){
    return CustomizationFrameWrapperInternal({
      onConfigurationChanged,
      onSessionSavedAsync,
      getDistributorsAsync,
      targetDiv,
      marketingSku,
      sku,
      userId,
      configurationId,
      showConfigurator,
      showConfigSelector,
      showSave,
      showShare,
      saveFlow,
      initialConfiguration,
      configurationStorageType,
      customizer,
      environment,
      onHelpClicked,
      onProductInfoClicked,
      onCloseClicked,
      trace
    });
  }
}

export default CustomizationFrameWrapper;