export const messages = {
  helpClicked: 'help-clicked',
  productInfoClicked: 'product-info-clicked',
  closeClicked: 'close-clicked',
  configurationChanged: 'configuration-changed',
  getDistributors: 'get-distributors',
  getDistributorsSuccess: 'get-distributors-success',
  getDistributorsError: 'get-distributors-error',
  sessionSaved: 'session-saved',
  sessionSavedSuccess: 'session-saved-success',
  sessionSavedError: 'session-saved-error',
}