/**
 * Available customizers
 * @readonly
 * @enum {string}
 */
export const Customizers = Object.freeze({
  personalProtectiveEquipment: "PPE"
});

/**
 * Available save flows
 * @readonly
 * @enum {string}
 */
export const SaveFlows = Object.freeze({
  cart: 'cart',
  email: 'email'
});

/**
 * Available storage types
 * @readonly
 * @enum {string}
 */
export const ConfigurationStorageTypes = Object.freeze({
  sessionStorage: 's',
  localStorage: 'l',
  inMemoryStorage: 'm'
});

/**
 * Available environments
 * @readonly
 * @enum {string}
 */
export const Environments = Object.freeze({
  preview: 'preview',
  production: 'admin-fts'
});
